import React from "react";

function Footer() {
    return (
        <div className="no-print">
            <footer className="footer-area section-padding">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="footer-text text-center wow fadeInDown" data-wow-delay="0.3s">
                                <ul className="social-icon">
                                    <li>
                                        <a className="instagram" href="https://linkedin.com/in/adam-lathan-actuary"><i className="icon-social-linkedin"></i></a>
                                    </li>
                                    <li>
                                        <a className="instagram" href="https://github.com/lathaniel"><i className="icon-social-github"></i></a>
                                    </li>
                                </ul>
                                <p>Copyright © 2018 UIdeck All Right Reserved</p>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    );
}

export default Footer;
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import {
  NavBar,
  Footer,
  Home,
  About,
  Resume,
  ResumeRecruiter,
  ComingSoon
} from "./components"

ReactDOM.render(
  <Router>
    <NavBar />
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/about" element={<About />} />
      <Route path="/projects" element={<ComingSoon />} />
      <Route path="/resume" element={<Resume />} />
      <Route path="/resume-recruiter" element={<ResumeRecruiter />} />
    </Routes>
    <Footer />
  </Router>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import React from "react";

export function ExperienceContainer() {
    return (
        <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-6">
                <div className="education wow fadeInRight" data-wow-delay="0.3s">
                    <ul className="timeline">
                        <li>
                            <i className="icon-graduation"></i>
                            <h2 className="timeline-title">Education</h2>
                        </li>
                        <li>
                            <div className="content-text">
                                <h3 className="line-title">Bsc - Drake University</h3>
                                <h3 className="line-title">Computer Science & Data Analytics</h3>
                                <span>2016 - 2020</span>
                                <p className="line-text"></p>
                            </div>
                        </li>
                    </ul>
                </div>
                <div className="Skills wow fadeInRight" data-wow-delay="0.3s">
                    <ul className="timeline">
                        <li>
                            <i className="icon-settings"></i>
                            <h2 className="timeline-title">Skills & Certifications</h2>
                        </li>
                        <li>
                            <div className="content-text">
                                <h3 className="line-title">Certifications</h3>
                                <span />
                                <div className="line-text">
                                    <h5 className="line-title">Associate of the Society of Actuaries (January 2022)</h5>
                                </div>
                                <br />
                                <div className="line-text">
                                    <h5 className="line-title">
                                        AWS Certified Cloud Practitioner (<a href="https://www.credly.com/badges/edca0ee0-cd8c-4056-a327-2dce155e9000">Feb 2022</a>)
                                    </h5>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div className="content-text">
                                <h3 className="line-title">Skills & Technology</h3>
                                <span />
                                <div className="line-text">
                                    <ul>
                                        <li>
                                            Highly proficient in Microsoft tools, including Excel and Power BI
                                        </li>
                                        <li>
                                            Creating cloud-based solutions with Amazon Web Services (AWS)
                                        </li>
                                        <li>
                                            Programming in Python, JavaScript/TypeScript, VBA, and C/C++
                                        </li>
                                        <li>
                                            Manipulating data using a variety of tools like R, SAS, and SQL
                                        </li>
                                        <li>
                                            Automation through RPA, web scraping, and creating APIs
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
                <div className="education wow fadeInRight" data-wow-delay="0.3s">
                    <ul className="timeline">
                        <li>
                            <i className="icon-bulb"></i>
                            <h2 className="timeline-title">Interests</h2>
                        </li>
                        <li>
                            <div className="content-text">
                                <h3 className="line-title">Data & Analytics</h3>
                                <span />
                                <div className="line-text">
                                    <ul>
                                        <li>
                                            Storytelling & visualization
                                        </li>
                                        <li>
                                            Data preparation & analysis
                                        </li>
                                    </ul>
                                </div>
                                <br />
                                <h3 className="line-title">Automation</h3>
                                <span />
                                <div className="line-text">
                                    <ul>
                                        <li>
                                            ETL streamlining & automation
                                        </li>
                                        <li>
                                            Automated controls and workflows around processes
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-6">
                <div className="experience wow fadeInRight" data-wow-delay="0.6s">
                    <ul className="timeline">
                        <li>
                            <i className="icon-briefcase"></i>
                            <h2 className="timeline-title">Experience</h2>
                        </li>
                        <li>
                            <div className="content-text">
                                <h3 className="line-title">Actuarial Associate - Principal Financial Group</h3>
                                <span>Sep 2021 - Present</span>
                                <div className="line-text">
                                    <h4 className="line-title">Cloud Platform Engineering</h4>
                                    I currently work on a centralized team of
                                    engineers developing cloud pipelines and solutions
                                    for AWS data services (e.g. DynamoDB, Aurora Postgres,
                                    Athena/Glue/S3). Our focuses include:
                                    <ul>
                                        <li>
                                            Creating repeatable, scalable data solutions for use throughout the company
                                        </li>
                                        <li>
                                            Using Infrastructure-as-Code (IaC) for cloud deployment
                                        </li>
                                        <li>
                                            Providing internal guidance regarding security and best practices
                                        </li>
                                        <li>
                                            Exploring cloud data curation patterns within Snowflake
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div className="content-text">
                                <h3 className="line-title">Actuarial Assistant - Principal Financial Group</h3>
                                <span>May 2020 - Aug 2021</span>
                                <div className="line-text">
                                    <h4 className="line-title">General Account Investments</h4>
                                    <ul>
                                        <li>
                                            Created Python package for aggregating and manipulating MG-ALFA input data
                                        </li>
                                        <li>
                                            Implemented automated workflows for documentation and version control
                                        </li>
                                        <li>
                                            Created and analyzed financial projections using MG-ALFA
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div className="content-text">
                                <h3 className="line-title">Actuarial Intern - Principal Financial Group</h3>
                                <span>Sep 2019 - May 2020</span>
                                <div className="line-text">
                                    <h4 className="line-title">General Account Investments</h4>
                                    <ul>
                                        <li>
                                            Developed Power BI dashboard for cross-business reporting
                                        </li>
                                        <li>
                                            Performed ETL operations on financial projections using R
                                        </li>
                                        <li>
                                            Automated recurring data retrieval with web scraping
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </li>
                        <li>
                            <div className="content-text">
                                <h3 className="line-title">Actuarial Intern - Cigna</h3>
                                <span>Jun 2019 - Aug 2019</span>
                                <div className="line-text">
                                    <h4 className="line-title">Rating Engine Redesign</h4>
                                    <ul>
                                        <li>
                                            Analyzed claims data to develop actual and expected analysis
                                        </li>
                                        <li>
                                            Worked on redesigning rating engines for healthcare pricing
                                        </li>
                                        <li>
                                            Helped develop new preventive care pricing methodology
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    )
}

function Resume() {
    return (
        <div id="resume" className="section-padding-less">
            <div className="container resume">
                <div className="row">
                    <div style={{
                        marginLeft: '30%',
                        marginRight: '30%',
                        textAlign: 'center'
                    }}>
                        <h3 >Adam Lathan</h3>
                        {/* <h5 >Denver, CO</h5> */}
                        <h6>
                            <em>
                                ASA with strong background in programming and a deep
                                interest in process improvement, automation, and modern
                                data pipelines for actuarial processes.
                            </em>
                        </h6>
                    </div>
                </div>
                <ExperienceContainer />
            </div>
        </div >
    )
}

export default Resume;

function ComingSoon() {
    return (
        <div className="about">
            <div className="container">
                <div className="row align-items-center my-5">
                    <div className="col-lg-7">
                        <h1 className="font-weight-light">Coming soon to a website near you!</h1>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ComingSoon;

function About() {
    return (
        <div className="about">
            <div className="container">
                <div className="row align-items-center my-5">
                    <div className="col-lg-7">
                    </div>
                    <div className="col-lg-5">
                        <h1 className="font-weight-light">About Me</h1>
                        <p>
                            {/* TODO */}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default About;
import * as React from 'react';

function NavBar() {
    return (
        <React.Fragment>
            <nav className="navbar navbar-expand-lg fixed-top scrolling-navbar indigo">
                <div className="container">
                    {/* Brand and toggle get grouped for better mobile display*/}
                    <div className="navbar-header">
                        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#main-navbar" aria-controls="main-navbar" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                            <span className="icon-menu"></span>
                            <span className="icon-menu"></span>
                            <span className="icon-menu"></span>
                        </button>
                        {/* <a href="/" className="navbar-brand"> Home</a> */}
                    </div>
                    <div className="collapse navbar-collapse" id="main-navbar">
                        <ul className="onepage-nev navbar-nav mr-auto w-100 justify-content-end clearfix">
                            <li className="nav-item active">
                                <a className="nav-link" href="/">
                                    Home
                                </a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="#about">
                                    About
                                </a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="#services">
                                    Interests
                                </a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="#resume">
                                    Resume
                                </a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="#contact">
                                    Contact
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>

                {/* Mobile Menu Start*/}
                <ul className="onepage-nev mobile-menu">
                    <li>
                        <a href="/">Home</a>
                    </li>
                    <li>
                        <a href="#about">About</a>
                    </li>
                    <li>
                        <a href="#services">Interests</a>
                    </li>
                    <li>
                        <a href="#resume">Resume</a>
                    </li>
                    <li>
                        <a href="#contact">Contact</a>
                    </li>
                </ul>
                {/* Mobile Menu End*/}
            </nav>
        </React.Fragment>
    )
}

export default NavBar;